html {
  scroll-behavior: smooth;
}

body {
  font-size: 16px;
  background-color: $background;
  overflow: hidden;
  color: $dark;
}

#root {
  overflow-x: hidden;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}

.main-wrapper {
  @extend .container-fluid;

  padding: $spacer * 0.5;

  @include media-breakpoint-up(sm) {
    padding: $spacer * 3;
  }

  @include media-breakpoint-up(lg) {
    padding: $spacer * 5;
  }
}

.dropdown-btn {
  border: 1px solid $input;
}

#main-container {
  height: calc(100vh - 92px);
  overflow-y: scroll;
  padding-top: $headerHeight;
}

#login-container {
  height: 100vh;
  overflow-y: scroll;
  padding-bottom: $footerHeight;
}

.content-area {
  padding: $spacer * 2;
  background: $background;
  position: relative;
  height: 100%;
  margin-right: 0;
  margin-left: auto;
  overflow: scroll;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;

  @include media-breakpoint-up(lg) {
    margin-left: $sidebarWidth;
    padding: $spacer * 5;
  }
}

// font classes
.nav-font {
  font-size: 18px;
  font-family: "Montserrat";
  font-weight: 500;
}

h1 {
  text-transform: uppercase;
  letter-spacing: 3.75px;
  line-height: 38px;
}

h2 {
  text-transform: uppercase;
  letter-spacing: 2.5px;
  line-height: 30px;
}

h3 {
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Montserrat";
}

.display-1 {
  font-size: $spacer * 1.875;
  font-weight: 500;
  line-height: 30px;
}

.display-2 {
  font-size: $spacer * 1.875;
  font-weight: 800;
  line-height: 30px;
}

.display-3 {
  font-size: $spacer;
  font-weight: 600;
  line-height: 30px;
}

.mb-medium {
  margin-bottom: 2.5rem;
}

.button-font {
  text-transform: uppercase;
  font-family: "Montserrat";
  font-weight: 600;
}

.light-font {
  font-family: "Montserrat";
  font-weight: 300;
}

// component classes
.component-card {
  position: relative;
  padding: $spacer;

  @include media-breakpoint-up(md) {
    padding: $spacer * 3;
    border: 0;
  }
}

.spinner-container {
  @extend .d-flex, .justify-content-center, .align-items-center, .py-5;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  height: $spacer * 10;
  width: $spacer * 10;
  background-color: $white;
}

// buttons

button.transparent {
  border: none;
  background-color: transparent;
}

.btn {
  @extend .button-font;
}

.btn-lg {
  @include media-breakpoint-up(sm) {
    width: $spacer * 19;
  }
}

.button-import {
  position: relative;
}

.btn-import {
  @extend .btn,
    .btn-lg,
    .btn-outline-medium,
    .d-flex,
    .align-items-center,
    .justify-content-center;

  position: relative;
  z-index: 10;

  svg {
    margin-right: $spacer * 1.5;

    path {
      fill: $medium;
      transition: $transition-base;
    }
  }

  &:hover {
    svg {
      path {
        fill: $white !important;
      }
    }
  }
}

.import-input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
