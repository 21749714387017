.mobile-main-nav {
  @extend .bg-dark;
  margin-top: 102px;
  border-right: 0 !important;

  .offcanvas-body {
    @extend .px-0;

    .nav {
      @extend .d-flex, .flex-column;

      > .nav-link {
        @extend .text-white, .nav-font, .d-flex, .justify-content-start;
  
        padding-top: $spacer;
        padding-bottom: $spacer;
        padding-left: 0;
        padding-right: 0;
        width: 100%;
  
        &.active,
        &.open {
          font-weight: 600;
          border-left: 5px solid $secondary;
        }
  
        &.active,
        &.open,
        &:hover {
          background-color: $activeDark;
        }
  
        img {
          margin-left: $spacer*1.5;
          margin-right: $spacer*1.5;
        }
  
        .span {
          width: 175px;
        }
      }
  
      .submenu {
        margin-left: $spacer*5;
        width: 75%;

        .nav-link {
          @extend .pe-0, .ps-3;
  
          width: 175px;
          font-size: 16px !important;
          color: $light;
  
          &.active {
            font-weight: 600;
          }        
        }
      }
    }
  }
}