#login-content-wrapper {
  .login-bg {
    display: flex;
    align-items: center;
    flex-grow: 1;
    min-height: 100vh;
    background-size: cover;
  }

  .login-row {
    @extend .align-items-center;

    padding-top: 4.25rem;
    padding-bottom: 4.25rem;
  }

  .login-body {
    @extend .text-start, .py-3, .pe-md-5;

    h1,
    h2 {
      text-transform: unset;
    }
  }

  svg {
    @extend .mx-auto, .ms-md-0, .d-block, .mb-5;
  }

  .card-signin {
    @extend .mx-auto;

    background-color: rgba(255, 255, 255, 0.85);
    max-width: 423px;
    border: 0;

    &::before {
      content: "";
      background-color: $primary;
      top: 0;
      right: 0;
      width: 100%;
      height: 3px;
      transition: all 0.8s linear;
    }

    .card-body {
      @extend .mx-4;

      min-height: 1px;
      padding: $spacer * 1.25;

      .card-signin-title {
        @extend .text-center, .py-1, .my-5;
      }

      .form-signin {
        width: 100%;

        input {
          &::placeholder {
            opacity: 0;
          }
        }
        .btn {
          @extend .w-100;

          letter-spacing: 0.1rem;
          padding: 1rem;
          transition: all 0.2s;
        }
      }
    }
  }
}
