.publish-bar {
  position: sticky;
  top: 0px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: $spacer*2;
  margin-bottom: $spacer*3;
  background: $white;

  button {
    width: 100%;
    margin: $spacer*0.5 0;
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;
    flex-wrap: wrap;

    button {
      width: 50%;
    }
  }

  @include media-breakpoint-up(md) {
    flex-wrap: unset;

    button {
      width: $spacer*14;
      margin: 0;
    }
  }
}