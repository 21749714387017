.frontend-client-information {
  padding-bottom: $spacer * 5;

  .client-information-image {
    figure {
      max-width: $spacer * 15;
      @include media-breakpoint-up(md) {
        padding-right: $spacer * 5;
        max-width: 100%;
      }
    }
  }

  .client-information-details {
    &.details-border {
      border-top: 1px solid $input;
      @include media-breakpoint-up(md) {
        border-top: 0;
        border-left: 1px solid $input;
        padding-left: $spacer * 5;
      }
    }

    h1 {
      margin-bottom: $spacer * 2;
    }

    .signature {
      p {
        @extend .mb-0;
      }
    }
  }
}
