.add-media-button {
  @extend .mb-4;

  .add-media-button-container {

    .show-image-container {
      position: relative;

      .show-image-buttons {
        // position: absolute;
        // top: 0;
        // // right: 0;
        // width: 100%;
        // display: flex;
        // justify-content: flex-end;
        // align-items: flex-start;
        button {
          border-radius: 50%;
        }

        svg {
          color: #000;
          fill: #000;
        }
      }
    }
  }
}