.sidebar-wrapper {
  display: none;
  @include media-breakpoint-up(lg) {

    display: block;
    padding-top: 102px;
    width: 15rem;
    left: 0;
    background-color: #363636;
    color: #fff;
    margin-left: 0;
    position: relative;
    min-height: 100vh;
    

    .sidebar-container {
      height: 100%;
      background-color: $medium;
      margin-right: 0;
      margin-left: auto;
      -moz-box-sizing:border-box;
      -webkit-box-sizing:border-box;
      -o-box-sizing:border-box;
      -ms-box-sizing:border-box;
      box-sizing:border-box;

      /* width */
      &::-webkit-scrollbar {
        width: 10px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: $medium !important; 
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $primary !important; 
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: $primary !important; 
      }
    }
  }

  .nav {
    @extend .d-flex, .justify-content-end, .p-0;

    .menu-close {
      @extend .text-white, .nav-font;

      background-color: transparent;
      padding-left: 0;
      padding-right: 0;
      width: 100%;
      border: none;

      &:focus {
        border: none;
        outline: none;
      }
    }

    > .nav-link {
      @extend .text-white, .nav-font, .d-flex, .justify-content-between;

      padding: $spacer $spacer*1.5;
      width: 100%;
      position: relative;

      &.active,
      &.open {
        font-weight: 600;
        
        &::before {
          content: '';
          position: absolute;
          width: 5px;
          height: 100%;
          top: 0;
          left: 0;
          background-color: $secondary;
        }
      }

      &.active,
      &.open,
      &:hover {
        background-color: $activeDark;
      }

      img {
        margin: auto;
      }

      .span {
        width: 175px;
        margin-left: $spacer*0.75
      }
    }

    .submenu {
      .nav-link {
        @extend .pe-0, .ps-3;

        width: 175px;
        font-size: 16px !important;
        color: $light;

        &.active {
          font-weight: 600;
        }        
      }
    }
  }
}