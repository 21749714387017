.presentation-list {
  .presentation-list-card {
    padding: $spacer * 1.5;
    margin-bottom: $spacer * 1.5;
    border: none;
    position: relative;

    .presentation-list-label {
      @extend .d-flex, .align-items-center, .justify-content-center;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 50px;
      background-color: #333;
      color: #fff;

      p {
        @extend .mb-0;
        transform: rotate(-90deg);
        font-size: $spacer * 0.875;
        white-space: nowrap;
      }
    }

    .presentation-list-container {
      @extend .d-flex,
        .flex-column,
        .flex-md-row,
        .justify-content-between,
        .align-items-start,
        .align-items-md-center;

      padding-left: 50px;

      .presentation-list-title {
        @extend .mb-2, .mb-md-3;
      }

      .presentation-list-details {
        @extend .d-flex, .flex-column, .flex-md-row;

        .presentation-list-created-date {
          @include media-breakpoint-up(md) {
            margin-right: $spacer;
          }
        }
      }
    }
  }
}
