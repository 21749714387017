/* montserrat-300 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('../../fonts/montserrat-v18-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../fonts/montserrat-v18-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/montserrat-v18-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/montserrat-v18-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/montserrat-v18-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/montserrat-v18-latin-300.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: url('../../fonts/montserrat-v18-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../fonts/montserrat-v18-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/montserrat-v18-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/montserrat-v18-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/montserrat-v18-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/montserrat-v18-latin-italic.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('../../fonts/montserrat-v18-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../fonts/montserrat-v18-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/montserrat-v18-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/montserrat-v18-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/montserrat-v18-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/montserrat-v18-latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('../../fonts/montserrat-v18-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../fonts/montserrat-v18-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/montserrat-v18-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/montserrat-v18-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/montserrat-v18-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/montserrat-v18-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-800 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('../../fonts/montserrat-v18-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../fonts/montserrat-v18-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/montserrat-v18-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/montserrat-v18-latin-800.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/montserrat-v18-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/montserrat-v18-latin-800.svg#Montserrat') format('svg'); /* Legacy iOS */
}
