.collection-list {

  p {
    overflow-wrap: anywhere;
  }

  .collection-list-title {
    padding-bottom: $spacer*2.25;
    border-bottom: 1px solid $listBorder;
  }
  .collection-list-card {
    width: 100%;
    display: inline-flex;
    flex-flow: row wrap;
    position: relative;
    padding-top: $spacer;
    padding-bottom: $spacer;
    border-bottom: 1px solid $listBorder;

    @include media-breakpoint-up(lg) {
      padding-top: $spacer*2;
      padding-bottom: $spacer*2;
    }

    .collection-list-col {
      margin-top: $spacer;
      margin-bottom: $spacer;

      @include media-breakpoint-up(lg) {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    .collection-list-image {
     flex: 1;
     padding-right: 16px;
    }

    .collection-list-info {
      flex: 2;
      padding-right: 48px;

      @include media-breakpoint-down(sm) {
        flex: unset;
        padding-right: 0;
        display: inline-block;
        width: 100%;
      }

      @include media-breakpoint-up(lg) {
        padding-right: $spacer;
      }
    }
    
    .collection-list-textarea {
      display: inline-block;
      width: 100%;

      @include media-breakpoint-up(lg) {
        flex: 3;
        padding-right: $spacer*4;
      }
    }

    .collection-list-buttons {
      position: absolute;
      top: $spacer*2;
      right: 0;
    }
  }
}